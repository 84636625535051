import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { above } from 'core-web/util/mediaqueries.js';
import { injectModel } from 'core-web/state';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Link from 'core-web/components/Link';
import AspectWrapper from 'core-web/components/AspectWrapper';
import BigArrowIcon from 'theme/components/icons/BigArrowIcon';
import styled from 'theme/libs/styled';
import colors from 'theme/config/colors';
import H2 from 'theme/components/base/headings/H2';
import ProductCard from 'theme/components/product/ProductCard';

const Flex = styled('div')({ display: 'flex' });

const ProductWrapper = styled('div')`
    margin-bottom: 4px;
    width: 50%;

    &:nth-of-type(even) {
        padding-left: 2px;
    }

    &:nth-of-type(odd) {
        padding-right: 2px;
    }

    &.wide {
        width: 100%;

        &:nth-of-type(odd),
        &:nth-of-type(even) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    ${above.md} {
        width: 25%;
        margin-bottom: 0;

        &:nth-of-type(-n + 3) {
            margin-bottom: 8px;
        }

        &:nth-of-type(odd),
        &:nth-of-type(even) {
            padding-right: 8px;
            padding-left: 0;
        }

        &.wide {
            width: 50%;

            &:nth-of-type(odd) {
                padding-right: 8px;
            }
        }
    }

    ${above.lg} {
        &:nth-of-type(-n + 3) {
            margin-bottom: 16px;
        }

        &:nth-of-type(odd),
        &:nth-of-type(even) {
            padding-right: 16px;
        }

        &.wide {
            &:nth-of-type(odd) {
                padding-right: 16px;
            }
        }
    }
`;

const Poster = styled('div')`
    & > div .arrow {
        transition: transform 0.25s ease;
    }

    ${above.lg} {
        &:hover > div .arrow {
            transform: translateX(4px);
        }
    }
`;

const ArrowWrapper = styled('div')`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 8px 8px 0;

    ${above.lg} {
        margin: 0 12px 12px 0;
    }
`;

const FeaturedProducts = ({ customer, data, products, poster }) => {
    const [allProducts, setAllProducts] = useState(products);
    const linkUrl = data?.link?.url || null;
    const heading = data.heading || poster.heading;

    useEffect(() => {
        const handlePriceLists = async (products) => {
            if (!products.length) {
                return;
            }

            const { getProductPrices, pricelistSeed } = customer;

            if (pricelistSeed) {
                const productsFromPricelist = await getProductPrices(products);

                if (productsFromPricelist?.length && Array.isArray(productsFromPricelist)) {
                    setAllProducts(productsFromPricelist);

                    return;
                }
            }

            setAllProducts(products);
        };

        handlePriceLists(products);
    }, [products, customer?.pricelistSeed]);

    return (
        <Flex flexWrap="wrap" mr={[0, '-8px', '-16px']}>
            <Poster
                as={linkUrl ? Link : 'div'}
                to={linkUrl || null}
                pr={['2px', '8px', '16px']}
                mb={['4px', '8px', '16px']}
                width={['50%', '25%']}
            >
                <AspectWrapper ratio={[205 / 360, products.length < 2 ? 418 / 360 : null, 340 / 460]}>
                    <Flex p={['12px', null, '20px']} justifyContent="center" alignItems="center" bg={colors.darkGrey}>
                        <H2 textAlign="center" color={colors.white}>
                            &nbsp;{heading}&nbsp;
                        </H2>
                        {linkUrl && (
                            <ArrowWrapper className="arrow" zIndex="10">
                                <BigArrowIcon width="31px" height="24px" color={colors.white} direction="right" />
                            </ArrowWrapper>
                        )}
                    </Flex>
                </AspectWrapper>
            </Poster>
            <Below
                breakpoint="md"
                render={() =>
                    allProducts.map((product, index) => {
                        const isWide = (products.length === 4 && index === 3) || (products.length === 6 && index === 5);

                        return (
                            <ProductWrapper key={product.objectID} className={isWide ? 'wide' : ''}>
                                <ProductCard
                                    isWide={isWide}
                                    data={product}
                                    hideItems={['compare']}
                                    imageSizes={isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                />
                            </ProductWrapper>
                        );
                    })
                }
            />
            <Above
                breakpoint="md"
                render={() =>
                    (allProducts || products).map((product, index) => {
                        const isWide =
                            (products.length === 4 && (index === 1 || index === 2 || index === 3)) ||
                            (products.length === 5 && (index === 3 || index === 4)) ||
                            (products.length === 6 && index === 5);

                        return (
                            <ProductWrapper key={product.objectID} className={isWide ? 'wide' : ''}>
                                <ProductCard
                                    isWide={isWide}
                                    data={product}
                                    hideItems={['compare']}
                                    imageSizes={isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                />
                            </ProductWrapper>
                        );
                    })
                }
            />
        </Flex>
    );
};

FeaturedProducts.propTypes = {
    customer: PropTypes.object.isRequired,
    data: PropTypes.object,
    poster: PropTypes.object,
    products: PropTypes.array.isRequired,
};

FeaturedProducts.defaultProps = {
    data: {},
    poster: {},
};

export default injectModel('customer')(FeaturedProducts);
